import '../sass/frontend.scss';
import { addFiguresParallax } from "./parts/utils/parallax-figures";
import { addAutoTitlesEffect, addTitlesEffect } from "./parts/utils/title-effect";
import './parts/header';
import simpleAccordion from './components/simple-accordion';
import tabs from './components/tabs';
import headerHandleColor from "./parts/utils/header-handle-color";
import showHeader from "./parts/header";
import { acceptCookies, addBodyClass, anchorLinkScroll, backbtnInit, lottieIconAnimation, scrollSectionAnimation, validateCheckoutFields, populateUtmFields, cfms7, } from './parts/helpers';
import { initCartItemActions } from "./parts/additions";
import layoutsMapping from "./components/layouts-maping";
import toggleSection from "./components/toggle-section";
import { create3dCarousel } from "./parts/utils/3d-carousel";
import pricingBlocks from "./components/pricing-blocks";
import customSelect from "./components/custom-select";
import { initCityFilter } from './parts/city-filter-init';
const dd = document.querySelector('.test');
dd && dd.classList.add('tett8');
const onReady = () => {
};
function ready() {
    simpleAccordion('accordion-title', 'click');
    simpleAccordion('tab-container__tab-title', 'hover');
    tabs();
    onReady();
    addFiguresParallax();
    headerHandleColor();
    addTitlesEffect();
    addAutoTitlesEffect();
    layoutsMapping();
    showHeader();
    pricingBlocks();
    anchorLinkScroll('.scrollButton ');
    lottieIconAnimation('.js-json-animation');
    toggleSection();
    create3dCarousel();
    scrollSectionAnimation();
    addBodyClass();
    acceptCookies();
    customSelect();
    initCartItemActions();
    validateCheckoutFields();
    backbtnInit();
    initCityFilter();
    cfms7();
    const formIds = ['wpcf7-f533-p398-o1', 'wpcf7-f1507-p402-o1', 'wpcf7-f10579-p10524-o1'];
    formIds.forEach(function (formId) {
        const form = document.getElementById(formId);
        if (form) {
            populateUtmFields(form);
        }
    });
}
window.document.addEventListener('DOMContentLoaded', ready);
